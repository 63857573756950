@import "../../pages/variables.scss";

.button {
    padding: 0px !important;
}

.filtered {
    svg {
        color: $ortex-color !important;
    }
}

.filter {
    @media screen and (max-width: $tablet-standard-width) {
        display: none;
    }
}
