@import "../../pages/variables.scss";

header div.notifications_wrapper {
}

.notifications {
    display: flex;
    align-items: center;
    justify-content: initial;
    padding-bottom: 10px;
    padding-top: 10px;
}

.notification_title {
    padding-left: 10px;
}

.notification_date {
    opacity: 0.6; /* Real browsers */
    filter: alpha(opacity = 60); /* MSIE */
}

.readNotificationsButton {
    margin: 0 auto;
    display: block;
    text-decoration: underline;
}

.popupContent {
    @media screen and (min-width: $tablet-large-width) {
        width: 400px;
    }
}

$repetition-count: infinite;
$animation-duration: 3.5s;

.bellAnimation {
    transform-origin: top center;
    animation: swingingBell $animation-duration $repetition-count;
}

@keyframes swingingBell {
    20% {
        transform: rotate3d(0, 0, 1, 0deg);
    }

    25% {
        transform: rotate3d(0, 0, 1, 15deg);
    }

    30% {
        transform: rotate3d(0, 0, 1, -15deg);
    }

    40% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    50% {
        transform: rotate3d(0, 0, 1, -5deg);
    }

    55% {
        transform: rotate3d(0, 0, 1, 15deg);
    }

    60% {
        transform: rotate3d(0, 0, 1, -15deg);
    }

    70% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, -5deg);
    }

    to {
        transform: rotate3d(0, 0, 1, 0deg);
    }
}

.badgeAnimation {
    animation: pulsingBadge $animation-duration ease-in-out infinite;
}

@keyframes pulsingBadge {
    0% {
        transform: scale3d(1, 1, 1);
    }

    20%,
    90% {
        transform: scale3d(1.07, 1.07, 1.07);
    }
    45% {
        transform: scale3d(1.09, 1.09, 1.09);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
