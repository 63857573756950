@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Mono&display=swap");
@import url("https://code.highcharts.com/css/annotations/popup.css");
@import url("https://code.highcharts.com/css/stocktools/gui.css");
@import "./variables.scss";

.highcharts-bindings-wrapper {
    z-index: 1 !important;
    .highcharts-submenu-wrapper {
        z-index: 1 !important;
    }
}

.MuiListItem-root {
    &:hover {
        background-color: $ortex-color !important;
    }
    &:active {
        background-color: $ortex-color !important;
    }
}

.MuiSelect-select {
    background-color: transparent !important;
}

::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
}

::-webkit-scrollbar-thumb {
    border-radius: calc(#{$scrollbar-size} / 2);
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

html {
    scrollbar-width: thin;
}

body {
    margin: 0;
    width: 100%;
    height: 100vh;
    font-family: $sans-serif;
    overflow-x: hidden;
    #__next {
        width: 100%;
        height: 100vh;
    }
    * {
        box-sizing: border-box;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

.box-title {
    font-size: 14px;
    text-align: left;
}

.Toastify__toast-container {
    width: unset !important;
    min-width: 320px !important;
    z-index: 2147483549 !important;
    .Toastify__toast-body {
        white-space: pre-line !important;
    }
}

.react-datepicker-popper {
    z-index: 2 !important;
}

@media (hover: none) {
    .menu-link-tooltip {
        display: none !important;
    }
}

.obfuscated {
    filter: blur(5px);
    user-select: none;
}

.ion-app {
    padding-top: constant(safe-area-inset-top);
    --ion-safe-area-top: 20px;
    --ion-safe-area-bottom: 20px;
}

.Toastify__toast-container {
    --safe-area-inset-top: env(safe-area-inset-top);
    top: var(--safe-area-inset-top) !important;
}

.invisible {
    visibility: hidden;
}

.highcharts-container {
    z-index: auto !important;
}

.react-calendar__tile--now {
    background: $ortex-color-darker;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: $ortex-color-lighter;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}

.tooltip-global {
    color: #000;
}

.highcharts-contextmenu {
    padding: 0;
    height: 100%;
}

.highcharts-menu {
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    height: auto;
    max-height: 100%;
    > hr {
        display: none;
    }
}

.highcharts-menu-item {
    padding: 2px !important;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 8px !important;
    border: 1px solid #a1a1a1;

    @media screen and (min-width: $tablet-standard-width) {
        margin-top: 2px;
        margin-bottom: 2px;
        padding: 4px !important;
        font-size: 9px !important;
    }
    @media screen and (min-width: $desktop-standard-width) {
        padding: 4px !important;
        font-size: 10px !important;
    }
}

.highcharts-range-input text,
input.highcharts-range-selector,
.highcharts-range-label text {
    color: $ortex-color !important;
    fill: $ortex-color !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

//CookieYes Fix
.video-placeholder-normal[style*="width: 0px"][style*="height: 0px"] {
    display: none;
}
