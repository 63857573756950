@import "../../pages/variables.scss";

.Checkbox {
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    white-space: nowrap;
    position: relative;
    user-select: none;
    min-height: 15px;
    font-size: 14px;
}
