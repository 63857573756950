@import "../../pages/variables.scss";

.fallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    height: 100%;
    margin: 0 auto;

    > .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > .actions {
            display: flex;
        }
    }

    > p {
        height: fit-content;
        max-width: 80vw;
    }
}
