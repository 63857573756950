@import "../../pages/variables.scss";

.bannerContainer {
    width: 100%;
    position: relative;
    top: 80px;
    z-index: 2;
    text-align: center;
    left: 0;
    @media screen and (min-width: $tablet-large-width) {
        position: absolute;
        margin-top: 0;
        height: 130px;
        top: 20px;
    }

    @keyframes shimmer {
        0% {
            background-position: -7rem top;
        }

        100% {
            background-position: 23.5rem top;
        }
    }

    .promotextBanner {
        align-self: center;
        padding-top: 15px;
        text-align: center;
        padding: 10px 10px 0 10px;

        p {
            display: inline-block;
            color: white;
            font-weight: bold;
            background: #ffffff -webkit-gradient(linear, 100% 0, 0 0, from(#ffffff), color-stop(0.5, #dc0029), to(#ffffff));
            background-position: -2rem top;
            background-repeat: no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-animation-name: shimmer;
            -webkit-animation-duration: 2.5s;
            -webkit-animation-iteration-count: infinite;
            -webkit-background-size: 8rem 100%;
        }

        @media screen and (max-width: $mobile-large-width) {
            p {
                padding-top: 5px;
            }
        }
    }

    .banner {
        background-color: #0f55a6;
        max-width: 1400px;
        max-height: 100%;
        height: fit-content;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media screen and (min-width: $tablet-large-width) {
            width: 70vw;
            border-radius: 32px;
        }

        .redBackground {
            position: absolute;
            bottom: 0;
            height: 65px;
            max-width: 1400px;
            border-radius: 34px;
            width: 70vw;
            background-color: #dc0029;
            z-index: 1;
            display: none;
            @media screen and (min-width: $tablet-large-width) {
                width: 70vw;
                border-radius: 34px;
                display: block;
            }
        }

        .container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        }

        .discountCircle {
            background-color: white;
            border-radius: 100%;
            width: 115px;
            height: 115px;
            z-index: 10;
            margin-left: -30px;
            color: #0f55a6;
            text-transform: uppercase;
            display: none;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            margin-right: 30px;
            line-height: 28px;

            @media screen and (min-width: $tablet-large-width) {
                display: flex;
                width: 100px;
                height: 100px;
            }
            @media screen and (min-width: $tablet-small-width) and (max-width: $desktop-medium-width) {
                margin-right: 15px;
            }
            @media screen and (min-width: $desktop-extra-large-width) {
                margin-left: -90px;
            }
            p {
                letter-spacing: 0.48px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

                &:nth-child(2) {
                    font-weight: bold;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            @media screen and (min-width: $tablet-large-width) {
                align-items: flex-start;
                flex-direction: row;
                justify-content: space-between;
                height: 130px;
                width: 86%;
                margin: 10px;
                padding: 5px 0;
            }

            .discountCircle {
                display: flex;
                margin: 0;
                @media screen and (min-width: $tablet-large-width) {
                    display: none;
                }
            }
        }

        .promoTitle {
            z-index: 10;
            margin: 20px 20px 10px;
            @media screen and (min-width: $tablet-large-width) {
                margin: 0;
            }
            .stars {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 15px;
                }
            }

            p {
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
                letter-spacing: 1.08px;

                text-align: left;
                text-shadow: 0px 3px 6px #00000029;
                font-weight: bold;
                line-height: 37px;
            }
        }
        .countdownContainer {
            z-index: 10;
            background-color: #dc0029;
            width: 100%;
            padding: 10px 0;
            margin-top: 15px;
            @media screen and (min-width: $tablet-large-width) {
                background-color: transparent;
                margin: 0;
                padding: 0;
                width: fit-content;
            }

            p {
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
                letter-spacing: 0.54px;
                font-weight: bold;
                text-shadow: 0px 3px 6px #00000029;
                text-transform: uppercase;
            }
            .countdown {
                display: flex;
                margin-top: 5px;
                justify-content: center;
                @media screen and (min-width: $tablet-large-width) {
                    justify-content: flex-start;
                }
                .countdownElement {
                    width: 65px;
                    text-align: center;
                    @media screen and (min-width: $tablet-large-width) {
                        width: 90px;
                    }
                    > div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .digit {
                        // font-family: $monospace;
                        letter-spacing: 1.08px;
                        border: 1px solid white;
                        padding: 5px;

                        @media screen and (min-width: $tablet-large-width) {
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}
