@import "../../pages/variables.scss";

.select {
    width: 100%;
    font-size: 12px;
    height: 30px;

    > input {
        &::placeholder {
            font-size: 12px !important;
            font-family: "Roboto", sans-serif;
            color: $grey-lighter;
            opacity: 1;
        }

        &:last-child {
            background-color: red;
        }
    }
}
