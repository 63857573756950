@import "../../pages/variables.scss";

.ContentLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    padding-bottom: env(safe-area-inset-bottom);

    @media screen and (orientation: landscape) {
        padding-right: env(safe-area-inset-right);
        padding-left: env(safe-area-inset-left);

    }

    .container {
        height: 100%;
        overflow: auto;

        @media screen and (min-width: $laptop-standard-width) {
            overflow: hidden;
        }
    }

    .auto-scroll{
        overflow: auto !important;
    }
}
