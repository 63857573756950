@import "../../pages/variables.scss";

.StockLinks {
    position: fixed;
    width: 100%;
    z-index: 6;
    overflow-x: hidden;

    @media screen and (min-width: $desktop-medium-width) {
        padding: 0;
        display: flex;
        align-items: center;
    }

    .StockLinksWrapper {
        width: 100%;
        height: 48px;
        display: flex;
        align-items: flex-start;
        position: relative;
        z-index: 4;
    }
    .StockLinksStock {
        z-index: 4;
        position: absolute;
        left: 0;
        height: 100%;
        padding: 0 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @media screen and (min-width: $desktop-medium-width) {
            padding: 0 10px;
            position: relative;
        }
    }
    .StockLinksLogo {
        > span {
            height: 25px !important;
            position: static !important;
            img {
                width: unset !important;
                height: 100% !important;
                margin: unset !important;
                position: static !important;
                max-width: 60px !important;
                min-width: unset !important;
                min-height: unset !important;
                max-height: unset !important;
            }

            @media screen and (min-width: $desktop-medium-width) {
                height: 35px !important;

                img {
                    max-width: 150px !important;
                }
            }
        }
    }
    .StockLinkInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
    }
    .StockLinkInfoHeader {
        font-size: 12px;
        font-weight: 700;

        @media screen and (min-width: $tablet-standard-width) {
            font-size: 12px;
        }

        @media screen and (min-width: $laptop-standard-width) {
            font-size: 12px !important;
        }

        @media screen and (min-width: $desktop-standard-width) {
            font-size: 14px !important;
        }

        @media screen and (min-width: $desktop-4k-width) {
            font-size: 16px !important;
        }
    }
    .StockLinkInfoSubHeader {
        font-size: 10px;
        font-weight: 600;

        @media screen and (min-width: $tablet-standard-width) {
            font-size: 10px;
        }

        @media screen and (min-width: $laptop-standard-width) {
            font-size: 10px !important;
        }

        @media screen and (min-width: $desktop-standard-width) {
            font-size: 12px !important;
        }

        @media screen and (min-width: $desktop-4k-width) {
            font-size: 14px !important;
        }
    }

    .arrow {
        position: absolute;
        z-index: 2;
        top: 14px;
        opacity: 1;
        transition: opacity 0.2s;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        @media screen and (min-width: $tablet-large-width) {
            display: none;
        }
        > i {
            font-size: 16px;
        }
        &.right {
            right: -6px;
            justify-content: flex-end;
            @media screen and (min-width: $tablet-large-width) {
                right: 16px;
            }
        }
        &.left {
            left: 0px;
            justify-content: flex-start;
            @media screen and (min-width: $tablet-large-width) {
                left: 16px;
            }
        }

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }
    .links {
        width: 100%;
        height: 100%;
        overflow: auto;
        scrollbar-width: none;
        &.scrolleableLeft {
            width: calc(100% - 30px);
            margin-right: auto;
            @media screen and (min-width: $tablet-large-width) {
                width: 100%;
            }
        }
        &.scrolleableRight {
            width: calc(100% - 30px);
            margin-left: auto;
            @media screen and (min-width: $tablet-large-width) {
                width: 100%;
            }
        }
        > div {
            height: 100%;
            display: flex;
            position: relative;
            .link {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                position: relative;

                a {
                    height: 100%;
                    width: calc(100% - 1px);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 10px;
                    font-size: 12px;
                    text-decoration: none;
                    z-index: 1;

                    @media screen and (min-width: $desktop-standard-width) {
                        font-size: 14px;
                    }

                    @media screen and (min-width: $desktop-4k-width) {
                        font-size: 16px;
                    }

                    span {
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                    &.selected {
                        border-bottom: 2px solid $ortex-color !important;
                    }
                }
                div {
                    position: absolute;
                    width: 100%;
                    height: calc(100% - 10px);
                }
                &.short-list {
                    max-width: unset;
                    @media screen and (min-width: $tablet-medium-width) {
                        width: fit-content;
                    }
                    a {
                        width: unset;
                    }
                }
                @media (hover: hover) {
                    &:hover {
                        .tooltip {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.stockTabs {
    width: calc(100% - $sidemenu_width);
}
