@import "../../pages/variables.scss";

.StockLinksStock {
    z-index: 4;
    height: 100%;
    padding: 0 6px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-shrink: 0;
    @media screen and (min-width: $desktop-medium-width) {
        padding: 0 10px;
        position: relative;
    }
}
.StockLinksLogo {
    > span {
        height: 25px !important;
        position: static !important;
        img {
            width: unset !important;
            height: 100% !important;
            margin: unset !important;
            position: static !important;
            max-width: 60px !important;
            min-width: unset !important;
            min-height: unset !important;
            max-height: unset !important;
        }

        @media screen and (min-width: $desktop-medium-width) {
            height: 35px !important;

            img {
                max-width: 150px !important;
            }
        }
    }
}

.StockLinkInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}
.StockLinkInfoHeader {
    font-size: 12px;
    font-weight: 700;

    @media screen and (min-width: $tablet-standard-width) {
        font-size: 12px;
    }

    @media screen and (min-width: $laptop-standard-width) {
        font-size: 12px !important;
    }

    @media screen and (min-width: $desktop-standard-width) {
        font-size: 14px !important;
    }

    @media screen and (min-width: $desktop-4k-width) {
        font-size: 16px !important;
    }
}
.StockLinkInfoSubHeader {
    font-size: 10px;
    font-weight: 600;

    @media screen and (min-width: $tablet-standard-width) {
        font-size: 10px;
    }

    @media screen and (min-width: $laptop-standard-width) {
        font-size: 10px !important;
    }

    @media screen and (min-width: $desktop-standard-width) {
        font-size: 12px !important;
    }

    @media screen and (min-width: $desktop-4k-width) {
        font-size: 14px !important;
    }
}
