@import "../../pages/variables.scss";

.StackedBar {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    > div {
        height: 16px;
        transition: background-color 0.1s ease-in-out;
    }
}
