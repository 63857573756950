@import "../../pages/variables.scss";

.RichTextEditor {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    min-width: 350px;
    .toolbar {
        padding: 0 5px;
        position: relative;
        > div {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
        }
        &.read-only {
            padding: 0;
            > div {
                padding: 0;
                border-bottom-width: 0;
                justify-content: flex-end;
            }
        }
    }
    .editor {
        flex: 1;
        overflow: auto;
        > div {
            height: 100%;
            * [data-block] {
                margin-bottom: 5px;
            }
            figure {
                margin: 0;
                img {
                    width: 100%;
                }
                > div > div {
                    height: 0;
                    width: 100%;
                    position: relative;
                    padding-bottom: 56.25%;
                    iframe {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                }
            }
        }
    }
    .options {
        padding: 0 5px;
        > div {
            display: flex;
            justify-content: flex-end;
            padding: 5px 0;
        }
    }
    .dropdown {
        height: fit-content !important;
        max-height: 350px;
        width: 250px;
        overflow: auto;
        position: fixed;
        z-index: 2;
        opacity: 0.9;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        > * {
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }
        > *:last-child {
            border-bottom: none;
        }
        .mention {
            padding: 5px;
            cursor: pointer;
            p {
                margin-bottom: 0;
                &:first-child {
                    font-size: 17px;
                }
                &:last-child {
                    font-size: 12px;
                }
            }
        }
    }
    .appbar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .button-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            > button:last-child {
                margin-right: 8px;
            }
        }
    }
}

.modal {
    height: 450px;
    @media screen and (min-width: $mobile-small-width) {
        height: 200px;
    }
    @media screen and (min-width: $mobile-medium-width) and (min-height: $mobile-medium-width) {
        height: 650px;
    }
    @media screen and (min-width: $tablet-small-width) {
        height: 500px;
    }
    @media screen and (min-width: $tablet-small-width) and (min-height: $tablet-small-width) {
        height: 900px;
    }
    @media screen and (min-width: $tablet-large-width) {
        height: 450px;
    }
    @media screen and (min-width: $tablet-large-width) and (min-height: $tablet-small-width) {
        height: 650px;
    }
    @media screen and (min-width: $desktop-medium-width) {
        height: 600px;
    }
    @media screen and (min-width: $desktop-medium-width) and (min-height: $tablet-medium-width) {
        height: 800px;
    }
}

.ortexlink {
    > div {
        label {
            width: 25px !important;
            margin: 3px;

            input {
                visibility: hidden;
                width: 0 !important;
                margin: 0 !important;
                &:checked + i {
                    color: $ortex-color;
                }
            }

            i {
                font-size: 25px;
                cursor: pointer;
            }
        }
    }

    > label {
        justify-content: flex-start;

        > input {
            width: 140px !important;
        }
    }

    > input {
        cursor: pointer;
    }
}

.custom {
    i {
        font-size: 25px;
        margin-right: 5px;
    }
}
