@import "../../pages/variables.scss";

.fallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    height: calc(100vh - $stock-navigation-tabs   - $header-height);
    max-width: 95vw;
    margin: 0 auto;

    > .container {
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > .actions {
            display: flex;
        }
    }

    > p {
        height: fit-content;
        max-width: 80vw;
    }

    h4 {
        @media (max-width: $mobile-large-width) {
            font-size: 30px;
        }
    }
}
