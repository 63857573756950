@import "../../pages/variables.scss";

.PaymentStripeForm {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media screen and (min-width: $laptop-standard-width) {
        height: 100%;
    }

    form {
        width: 100%;

        label {
            display: flex;
            flex-direction: column;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            -webkit-font-smoothing: antialiased;
            width: calc(100% - 8px);
            font-size: 0.93rem;
            line-height: 1.07;
            margin: 10px 4px;

            input {
                color: inherit;
                margin-top: 4px;
                padding: 0.75rem;
                appearance: none;
                vertical-align: middle;
                border-radius: 5px;
                font-size: 1rem;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
                transition:
                    background 0.15s ease,
                    border 0.15s ease,
                    box-shadow 0.15s ease,
                    color 0.15s ease;
                width: 100%;

                &.dark {
                    background-color: rgb(48, 49, 61);
                    box-shadow:
                        0px 2px 4px rgb(0 0 0 / 50%),
                        0px 1px 6px rgb(0 0 0 / 25%);
                    border: 1px solid rgb(66, 67, 83);

                    &:focus {
                        outline: 0;
                        border-color: hsla(132, 53%, 69%, 40%);
                        box-shadow:
                            0 2px 4px rgb(0, 0, 0, 0.5),
                            0 1px 6px rgb(0, 0, 0, 0.25),
                            0 0 0 3px hsla(132, 53%, 69%, 40%),
                            0 1px 1px 0 rgba(255, 255, 255, 0.12);
                    }
                }

                &.light {
                    box-shadow:
                        0px 1px 1px rgba(0, 0, 0, 0.03),
                        0px 3px 6px rgba(0, 0, 0, 0.02);
                    border: 1px solid #e6e6e6;
                    &:focus {
                        outline: 2px;
                        border-color: hsla(210, 96%, 45%, 50%);
                        box-shadow:
                            0px 2px 4px rgba(0, 0, 0, 0.03),
                            0px 3px 6px rgba(0, 0, 0, 0.02),
                            0 0 0 3px hsla(210, 96%, 45%, 0.25),
                            0 1px 1px 0 rgba(0, 0, 0, 0.08);
                    }
                }

                &::placeholder {
                    color: rgb(158, 158, 158);
                }

                &.invalid {
                    color: rgb(254, 135, 161);
                    border-color: rgb(254, 135, 161);
                    box-shadow:
                        0px 2px 4px rgba(0, 0, 0, 0.5),
                        0px 1px 6px rgba(0, 0, 0, 0.25),
                        0 0 0 1px rgb(254, 135, 161);
                    &::placeholder {
                        color: inherit;
                    }
                }
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    box-shadow: 0 0 0 32px rgb(48, 49, 61) inset !important;
                    -webkit-box-shadow: 0 0 0 32px rgb(48, 49, 61) inset !important;
                    -webkit-text-fill-color: rgb(255, 255, 255) !important;
                }
            }
            @media screen and (min-width: $mobile-small-width) {
                width: calc(50% - 10px);
                display: inline-flex;
                &:nth-of-type(1) {
                    margin-right: 6px;
                }
                &:nth-of-type(2) {
                    margin-left: 6px;
                }
            }
        }
        button:not(.backBtn) {
            width: calc(100% - 8px);
            height: 45px;
            border: none;
            // margin: 0px 4px 0;
            padding: 10px 20px;
            border-radius: 10px;
            font-size: 16px;
            cursor: pointer;
            color: $white;
            background-color: $ortex-color;
            transition: background-color 0.3s;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            margin: 20px 0;
            &:disabled {
                background-color: $grey-lighter;
            }
            &:hover {
                background-color: $ortex-color-darker;
                &:disabled {
                    background-color: $grey;
                }
            }
            @media screen and (min-width: $tablet-large-width) {
                display: none;
            }
        }

        .backBtn {
            padding: 0;
            margin-left: 5px;
            border: none;
            color: $grey;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-size: 14px;
            background-color: transparent;
        }
    }
}

.promo {
    display: flex;
    align-items: flex-end;
    width: calc(100% - 10px) !important;
    label {
        width: calc(100% - 10px) !important;
    }
    .applyPromoBtn {
        display: block !important;
        width: auto !important;
        margin-bottom: 10px !important;
        box-shadow: none !important;
    }
}
