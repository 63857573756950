@import "../../pages/variables.scss";

.SignUpForm {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    padding-top: env(safe-area-inset-top);
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: 3%;

    @media screen and (min-width: $tablet-standard-width) {
        margin-top: 5%;
    }
    @media screen and (min-width: $desktop-4k-width) {
        justify-content: flex-start;
    }
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    margin-top: 2;
    margin: auto;
    max-width: 600px;
    @media screen and (min-width: $desktop-4k-width) {
        max-width: 1100px;
    }
}

.btn {
    height: 40px;
    &:disabled {
        pointer-events: visible;
        &:hover {
            cursor: not-allowed;
        }
    }
}

.text-field {
    border: 0;
    outline: none;
    background-color: transparent;
    @media screen and (min-width: $desktop-standard-width) {
        * {
            font-size: 1.2rem;
        }
        label {
            font-size: 1rem;
        }
        p {
            font-size: 0.8rem;
        }
    }
}

.grid-item {
    height: 75px;
    @media screen and (min-width: $desktop-4k-width) {
        height: 100px;
    }
}

.sign-in {
    display: block;
    width: fit-content;
    margin: auto;
    padding-bottom: 10px;
    color: $ortex-color;
    text-decoration: none;
    cursor: pointer;

    @media screen and (min-width: $tablet-standard-width) {
        display: inline;
    }
}

.title-font {
    font-size: 1.5rem;
    font-weight: 800;
    align-self: center;
    text-align: center;
    @media screen and (min-width: $tablet-standard-width) {
        font-size: 2rem;
    }
    @media screen and (min-width: $desktop-4k-width) {
        font-size: 3rem;
    }
}

.img-container {
    display: block;
    width: 150px;
    height: auto;

    @media screen and (min-width: $desktop-4k-width) {
        width: 450px;
        height: 90px;
    }
}

#captcha_placeholder {
    margin: auto;
}

.btn {
    height: 40px;

    @media screen and (min-width: $desktop-4k-width) {
        height: 75px;
        font-size: 30px;
    }

    &:disabled {
        pointer-events: visible;
        &:hover {
            cursor: not-allowed;
        }
    }
}

button.socialBtns {
    display: flex;
    justify-content: flex-start;
    text-transform: none;
    position: relative;
    padding: 0 8px 0 52px;
    background: transparent;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 10px;
    font-size: 16px;
    font-family: inherit;
    height: 52px;
    border: 1px solid #c2c8d0;
    border-radius: 3px;
    color: #2d333a;
    cursor: pointer;
    outline: 0;
    /*  transition: background-color 0.15s ease-in-out; */

    /* @media screen and (hover: hover) {
        &:hover {
            background-color: #e8e8e8;
        }
    } */
    @media screen and (max-width: $tablet-standard-width) {
        max-width: 100%;
        height: 40px;
    }
}

.googleBtn {
    span {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E");
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        position: absolute;
        left: 26px;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.appleBtn {
    span {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='170' xmlns='http://www.w3.org/2000/svg' height='170'%3E%3Cpath d='M150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-.9 2.61-1.85 5.11-2.86 7.51zM119.11 7.24c0 8.102-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375a25.222 25.222 0 0 1-.188-3.07c0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.311 11.45-8.597 4.62-2.252 8.99-3.497 13.1-3.71.12 1.083.17 2.166.17 3.24z'/%3E%3C/svg%3E");
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        position: absolute;
        left: 26px;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.appleDarkTheme {
    span {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='170' xmlns='http://www.w3.org/2000/svg' height='170'%3E%3Cpath fill='white' d='M150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-.9 2.61-1.85 5.11-2.86 7.51zM119.11 7.24c0 8.102-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375a25.222 25.222 0 0 1-.188-3.07c0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.311 11.45-8.597 4.62-2.252 8.99-3.497 13.1-3.71.12 1.083.17 2.166.17 3.24z'/%3E%3C/svg%3E");
    }
}
