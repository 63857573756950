@import "../../pages/variables.scss";

.cardTypography {
    font-size: 16px;
    @media screen and (min-width: $desktop-4k-width) {
        font-size: 18px;
    }
}

.card {
    padding: 0;
    border-radius: 1;
    overflow: hidden;
    width: 100%;

    .header {
        padding: 10px;
        padding-right: 25px;
        .title,
        .subtitle {
            display: flex;
            align-items: center;
            font-size: 12px;

            .stockName {
                font-weight: 800;
                margin-right: 10px;
                font-size: 16px;
                letter-spacing: 0px;
            }

            .stockTicker {
                font-size: 12px;
                letter-spacing: 0px;
            }
        }

        .space-between {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .subtitle {
            p {
                font-size: 12px;
                letter-spacing: 0px;
            }

            > * {
                margin-right: 5px;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;

        > p {
            font-size: 12px;
            letter-spacing: 0px;
            max-width: 80%;
        }

        .stockData {
            display: flex;
            width: 100%;
            margin-top: 10px;

            .contentSubtitle {
                font-size: 10px;
                letter-spacing: 0px;
            }

            .price {
                width: 40%;
                margin-right: 20px;

                .lastPrice {
                    > p {
                        &:last-child {
                            font-size: 16px;
                            letter-spacing: 0px;
                            font-weight: 800;
                        }
                    }
                }

                .priceTrend {
                    margin-top: 6px;
                    .chartContainer {
                        width: 100%;
                        aspect-ratio: 16/9;
                        > div[data-highcharts-chart] {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }

            .market {
                width: 50%;
                margin-right: 10px;
                .row {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    .profits {
                        > p {
                            font-size: 12px;
                            letter-spacing: 0px;

                            &:last-child {
                                font-weight: 800;
                            }
                        }

                        &:last-child {
                            text-align: end;
                        }

                        .priceVariation {
                            display: flex;
                            align-items: center;

                            p {
                                font-weight: 800;
                            }
                        }
                    }
                }
            }
        }
    }
}
