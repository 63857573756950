@import "../../pages/variables.scss";

.MacroEventsCalendar {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100% !important;

    .calendar {
        width: 100%;
        margin: 0 auto;
        overflow: auto;
        font-size: 14px;
        text-decoration: none !important;
        font-family: "Roboto", sans-serif;
        border: none;
        box-shadow:
            rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
            rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 4px;
    }

    .dark {
        background: $black-lighter;
        button {
            color: $white-medium;
            &:hover {
                color: black;
            }
        }
    }

    .events {
        overflow: auto;
        width: 50%;
        height: 100%;

        > div > p {
            font-size: 10px;
            margin: 3px;

            @media screen and (min-width: $desktop-medium-width) {
                font-size: 12px;
            }
            @media screen and (min-width: $desktop-medium-width) and (min-height: $tablet-medium-width) {
                font-size: 16px;
            }
        }
    }
}

.calendarDay {
    width: 100%;
    min-height: 45px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    @media screen and (min-width: $desktop-medium-width) {
        height: 45px;
    }

    .outsideCurrentMonth {
        opacity: 0.5;
    }

    .eventsChip {
        margin-bottom: 5px;
        white-space: break-spaces;
        word-wrap: break-word;
        text-align: center;
        height: fit-content;
        font-size: 10px;

        @media screen and (min-width: $desktop-medium-width) {
            font-size: 12px;
        }
    }
}
