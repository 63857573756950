@import "../../pages/variables.scss";

.SettingsPage {
    height: 100%;
    @media screen and (min-width: $tablet-standard-width) {
        height: 100%;
    }
    .title {
        font-size: 20px;
        font-weight: normal;
        margin-bottom: 10px;
        width: 100%;
        @media screen and (min-width: $tablet-standard-width) {
            font-size: 40px;
        }
    }
    .accordion-item{
        width:100%;

    }
    .settingsCard {
        height:100%;
        width:100%;
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        > div {
            margin-bottom: 0 !important;
            padding-bottom: 10px;
            width:100%;
            @media screen and (min-width: $tablet-standard-width) {
                width:80%;
            }
        }

        
        
        @media screen and (min-width: $tablet-standard-width) {
            width:90%;
        }
        @media screen and (min-width: $desktop-4k-width) {
            width:50%;
        }
        &.maxWidth {
            > div {
                width: 100% !important;
            }
        }
        .buttons-container {
            display: flex;
            width:fit-content;
            button {
                border: none;
                outline: none;
                padding: 8px 16px;
                cursor: pointer;
                font-size: 13px;
                font-weight: normal;
                white-space: nowrap;
                text-transform: uppercase;
                background: none;
                border: 1px solid $ortex-color;
            }
            &.color-scheme {
                margin-bottom: 20px;
                width: 182px;
                justify-content: flex-start;
            }
        }
        .settings-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;

            @media screen and (min-width: $tablet-standard-width) {
                flex-direction: row;
                align-items: center;
            }
            p {
                font-size: 14px;
                margin-bottom: 5px;
                width:45%;

                @media screen and (min-width: $mobile-small-width) {
                    margin-bottom: 0px;
                }
            }
            button {
                border: none;
                outline: none;
                padding: 8px 16px;
                cursor: pointer;
                font-size: 13px;
                font-weight: normal;
                white-space: nowrap;
                text-transform: uppercase;
                background: none;
                border: 1px solid $ortex-color;
            }

            .selector {
                width:45%;
                display:flex;
                justify-content: center;
                button {
                    width: 60px;
                    height: 30px;
                    padding: 0px;
                    border-color: $ortex-color;
                }
            }

            .picker-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width:45%;
                button {
                    width: 60px;
                    height: 30px;
                    padding: 0px;
                    border-color: $ortex-color;
                    border-radius: 4px !important;
                    margin-right: 10px;
                    transition: transform 0.2s;

                    &:active {
                        transform: scale(0.95);
                    }

                    &:disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }
            }
            .long-text {
                inline-size: 45%;
                width:45%;
                overflow-wrap: normal;
                hyphens: auto;
                overflow:hidden;
            }
        }

        .all-colors {
            width:100%;
            margin:auto;
            display: flex;
            justify-content: center;
            margin-top: 30px;
        }

        .subscription {
            flex-direction: column;
            align-items: flex-start;
            p {
                white-space: unset;
                margin-bottom: 10px;
                a,
                span {
                    color: $ortex-color;
                    cursor: pointer;
                    text-decoration: none;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .cancellationInfo {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;
            @media screen and (min-width: $laptop-standard-width) {
                flex-direction: row;
                > div {
                    width: 48%;
                    &:first-child {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .subscription-info {
                width:100%;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                @media screen and (min-width: $tablet-standard-width) {
                    flex-direction: row;
                    align-items: center;
                }
                @media screen and (min-width: $laptop-standard-width) {
                    justify-content: center;

                }
                > * {
                    width:100%;
                    @media screen and (min-width: calc($tablet-standard-width - 5px)) {
                        width:40%;
                    }
                }
                .subscription-data {
                    width:100%;
                    text-align: center;
                    @media screen and (min-width: calc($tablet-standard-width - 5px)) {
                        text-align: left;
                        width:60%;
                    }
                }
            }
        }
    }
    
    

    .content {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media screen and (min-width: $laptop-standard-width) {
            display:grid;
            grid-template-columns: 30% 20% 20% 30%;
            grid-template-rows: 80% 20%;
        }
        > div {
            width: 95%;
            margin: 5px auto;
            @media screen and (min-width: $laptop-standard-width) {
                width:95%;
                margin: 0 auto;
                &:nth-child(1){
                    grid-column: 1/2;
                    grid-row:1/2;
                }
                &:nth-child(2){
                    grid-column: 2/3;
                    grid-row:1/2;
                }
                &:nth-child(3){
                    grid-column: 3/4;
                    grid-row:1/2;
                }
                &:nth-child(4){
                    grid-column: 4/5;
                    grid-row:1/2;
                }
                &:nth-child(5){
                    height:90%;
                    width:100%;
                    margin: auto;
                    grid-column: 1/5;
                    grid-row:2/3;
                }
            }

            > div {
                margin-bottom: 15px;
            }

            
        }

        a {
            text-decoration: none;
            color: $ortex-color;
        }
    }
}

.modal-button {
    height: 30px;
    display: block;
    padding: 0 10px;
    cursor: pointer;
    font-size: 14px;
    margin-left: auto;
    transition: background-color 0.2s;
    border: 2px solid $ortex-color;
    border-radius: 15px;
}
