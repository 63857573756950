 @import "../../pages/variables.scss";
.MenuOverlay {
    position: fixed;
    top: 0;
    left: 260px;
    right: 0;
    height: 100vh;
}

.list {
    padding-top: env(safe-area-inset-top) !important;
    height:100%;
    overflow-y: auto;
    text-align: center;
    @media screen and (min-width: $tablet-standard-width) {
        overflow: hidden;
    }
    
    [id^="small-menu-item-20"] {
        display: none;
       }
}

.sidemenu-fontsize{
    font-size: 14px !important;
    @media screen and (min-width:$tablet-standard-width) {
        font-size: 15px !important;
    }
    @media screen and (min-width:$laptop-standard-width) {
        font-size: 16px !important;
    }
    @media screen and (min-width:$desktop-4k-width) {
        font-size: 17px !important;
    }
}

.menu-fontsize{
    font-size: 16px !important;

    @media screen and (min-width:$laptop-standard-width) {
        font-size: 18px !important;
    }
    @media screen and (min-width:$desktop-4k-width) {
        font-size: 20px !important;
    }
}

.dynamic-padding{
    padding: 5px !important;
    @media screen and (min-width:$desktop-standard-width) {
        padding: 6px !important;
    }
    @media screen and (min-width:$desktop-4k-width) {
        padding: 7px !important;
    }
}



