@import "../../pages/variables.scss";

.MenuPopup {
    position: fixed;
    min-width: 10vw;
    z-index: 1200;
   
    @media screen and (min-width: $desktop-4k-width) {
        margin-left: -9px;
    }
}


.menuFonts {
    font-size: 12px !important;
    margin-top:0;
    margin-bottom:0;
    @media screen and (min-width: $tablet-standard-width) {
        font-size: 12px !important;
    }

    @media screen and (min-width: $laptop-standard-width) {
        font-size: 12px !important;
    }

    @media screen and (min-width: $desktop-standard-width) {
        font-size: 12px !important;
    }

    @media screen and (min-width: $desktop-4k-width) {
        font-size: 18px !important;
        margin-top:4px;
        margin-bottom: 4px;
    }
}

.dynamic-fontsize{
    font-size: 14px !important;
    @media screen and (min-width:$tablet-standard-width) {
        font-size: 15px !important;
    }
    @media screen and (min-width:$laptop-standard-width) {
        font-size: 16px !important;
    }
    @media screen and (min-width:$desktop-4k-width) {
        font-size: 20px !important;
        margin-right: 8px;
    }
}

.dynamic-padding{
    padding: 5px !important;
    @media screen and (min-width:$desktop-standard-width) {
        padding: 6px !important;
    }
    @media screen and (min-width:$desktop-4k-width) {
        padding: 7px !important;
    }
}
