@import "../../pages/variables.scss";

.Module {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    position: relative;

    .title-container {
        position: sticky;
        top: 0px;
        z-index: 2;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        a {
            text-decoration: none;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        padding: 10px !important;

        @media screen and (min-width: $desktop-standard-width) {
            padding: 12px !important;
        }

        @media screen and (min-width: $desktop-4k-width) {
            padding: 24px !important;
        }

        .button {
            padding: 0px !important;
        }
        .customView {
            i {
                font-size: 14px;
            }
        }
        .title {
            display: flex;
            align-items: center;
            font-size: 18px;
            @media screen and (min-width: $desktop-medium-width) {
                font-size: 16px;
            }
            a {
                color: unset;
                text-decoration: none;
            }
        }
        .settings {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .logo {
            > span {
                height: 30px !important;
                position: static !important;
                @media screen and (min-width: $desktop-medium-width) {
                    height: 40px !important;
                }
                img {
                    width: unset !important;
                    height: 100% !important;
                    margin: unset !important;
                    position: static !important;
                    max-width: 150px !important;
                    min-width: unset !important;
                    min-height: unset !important;
                    max-height: unset !important;
                }
            }
        }

        .header-button {
            font-size: 14px;
            text-transform: initial;
            @media screen and (max-width: $desktop-medium-width) and (min-width: $tablet-standard-width) {
                font-size: 12px;
            }
        }
    }
    .no-padding-bottom {
        padding-bottom: 0px !important;
    }

    .content-container {
        overflow: auto;
        padding: 10px !important;

        @media screen and (min-width: $desktop-standard-width) {
            padding: 12px !important;
        }

        @media screen and (min-width: $desktop-4k-width) {
            padding: 24px !important;
        }
    }
    .no-padding {
        overflow: auto;
        padding: 0px !important;
    }

    .content {
        flex: 1;
        height: 0;
        margin-top: 15px;
    }
}

.modal {
    font-size: 14px;
    h1,
    h2,
    p {
        margin-bottom: 5px;
    }
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 17px;
    }
    a {
        color: $ortex-color;
    }
    figure {
        height: 0;
        margin: 0;
        position: relative;
        padding-bottom: 56.25%;
        iframe {
            width: 100%;
            height: 100%;
            border: none;
            position: absolute;
        }
    }
}

.child_cardContent {
    height: 100%;
}

// .header_text {
//     font-size: 16px;
// }
