@import "../../pages/variables.scss";

.dialog {
    padding-top: env(safe-area-inset-top);

    .content {
        a {
            color: $ortex-color;
        }
    }
}

.title_fullname_publishedat {
    padding-top: 5px;
    font-size: small;

    > div {
        &:last-child {
            text-align: end;
        }
    }
}
.secondDivider {
    margin-top: 15px !important;
}

.firstDivider,
.secondDivider {
    margin-bottom: 15px !important;
}

.font_actions {
    justify-content: space-between !important;
}

.draggableHandler {
    cursor: move;
}
