.SparkChart {
    .no-chart {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        height: 100%;
    }

    > div[data-highcharts-chart] {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: auto;
        width: 90% !important;
        height: 90% !important;
    }
}

// .SparkChart > div[data-highcharts-chart] {
//     width: 100% !important;
//     height: 100% !important;
// }
