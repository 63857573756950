@import "../../pages/variables.scss";

.Payment {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    position: relative;
    @media screen and (min-width: $laptop-standard-width) {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 70px;
        flex-direction: row;
        overflow: hidden;
    }

    .appSuscriberText {
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        max-width: 90vw;
    }

    .left-container {
        flex: 1;
        order: 1;
        padding: 30px;
        @media screen and (min-width: $laptop-standard-width) {
            order: 0;
            width: 65%;
        }
    }
    .right-container {
        display: flex;
        align-items: center;
        padding: 30px;
        margin-bottom: 15px;
        @media screen and (min-width: $laptop-standard-width) {
            width: 35%;
            height: 100vh;
            min-width: 360px;
            max-width: 800px;
            margin-bottom: 0;
        }
        > div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-height: 700px;
            height: 100%;
            width: 100%;
            > * {
                width: 100%;
                margin: 5px 0;
            }
            > div {
                display: flex;
                align-items: center;
            }
            .title {
                margin-top: 15px;

                .star-icon {
                    height: 35px;
                    margin-right: 8px;
                }
            }
            .advanced,
            .feature {
                display: none;
                @media screen and (min-width: $laptop-standard-width) {
                    display: flex;
                }
            }
            .upgrade span {
                color: $ortex-color;
                cursor: pointer;
            }
            hr {
                border-style: inset;
                border-color: $grey-lighter;
                display: none;
                @media screen and (min-width: $laptop-standard-width) {
                    display: block;
                }
            }
            .btn {
                display: none;
                width: 80%;
                margin: 0 auto;
                padding: 10px 20px;
                color: $white;
                cursor: pointer;
                margin-bottom: 15px;
                &:disabled {
                    pointer-events: visible;
                    &:hover {
                        cursor: not-allowed;
                    }
                }

                @media screen and (min-width: $laptop-standard-width) {
                    display: inline-block;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.logo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    min-height: 100px;

    @media screen and (min-width: $tablet-standard-width) {
        justify-content: flex-start;
    }
    @media screen and (min-width: $laptop-standard-width) {
        justify-content: flex-start;
        display: inline;
        position: absolute;
        left: 30px;
        top: 20px;
    }
}

.isAppSubscriber {
    .right-container {
        @media screen and (min-width: $desktop-small-width) {
            height: 85vh !important;
            max-height: 600px !important;
            margin: 5px 0;

            > div {
                max-height: 600px !important;
            }
        }

        @media screen and (min-width: $desktop-extra-large-width) {
            min-height: 750px !important;
            height: 50vh !important;

            > div {
                max-height: 700px !important;
            }
        }
    }

    .container {
        @media screen and (min-width: $desktop-small-width) {
            max-width: 600px;
        }

        .appSuscriberText {
            > div {
                &:first-child {
                    @media screen and (min-width: $desktop-extra-large-width) {
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
}

.hidden {
    visibility: hidden !important;
    z-index: -1 !important;
}

.discount {
    color: white !important;
    font-style: bold;
}
