@import "../../pages/variables.scss";
 
 

.slideItem {
    width: fit-content;
    padding: 0 10px;
    height: 100%;
    font-size: small;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 auto;

    p:first-child {
        font-weight: bold;
        // height:100% !important;
        overflow: hidden;
        width: fit-content;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
    }

    * {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    @media screen and (min-width: $desktop-4k-width) {
        font-size: medium;
        height: 40px;

    }
}

.tickertapeElement {
    height: 100%;
    min-width: 200px;
    user-select: none;
    transition: background-color 0.5s ease;
}



.tickerwrap {
   width: 100%;
   height: 25px;
   margin-top: 40px;
   > div {
        height: 25px !important;
        * {
            height: 100% !important;
        }
    }
   @media screen and (min-width:$laptop-standard-width) {
    margin-top: 0;
   }
   @media screen and (min-width:$desktop-4k-width) {
    height: 40px;
     > div {
        height: 40px !important;
        * {
            height: 100% !important;
        }
     }
   }
}

