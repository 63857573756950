@import "../../pages/variables.scss";

.countdownContainer {
    > div {
        @media screen and (max-width: $tablet-small-width) {
            top: 0;
        }
    }
}

.SignUpDiscountForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    font-size: 15px;

    > div {
        align-self: flex-start;
    }
    form {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        @media screen and (min-width: $tablet-medium-width) {
            margin-top: 100px;
        }
        .fields {
            width: 100%;
            display: grid;
            gap: 20px;
            grid-template-columns: 1fr 1fr;
        }
        .form-field {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 5px;
            margin-top: 30px;
            position: relative;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            input {
                flex: 1;
                width: 100%;
                height: 18px;
                border: none;
                outline: none;
                font-size: 14px;
                background-color: transparent;
            }
            p {
                font-size: 11px;
                position: absolute;
                top: 27px;
                left: 2px;
            }
            button {
                width: 20px;
                height: 20px;
                svg {
                    width: inherit;
                    height: inherit;
                }
            }
        }
        button {
            padding: 0;
            border: none;
            outline: none;
            font-size: 15px;
            cursor: pointer;
            background-color: transparent;
        }
        .checkbox {
            width: 100%;
            margin-top: 15px;
            label {
                text-transform: none;
                white-space: break-spaces;
            }
        }

        .sign-up {
            width: 100%;
            height: 42px;
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $ortex-color;
            transition: background-color 0.3s;
            border-radius: 10px;
            margin-top: 50px;

            @media screen and (hover: hover) {
                &:hover {
                    background-color: #4ac4bf;
                }
            }
            &:disabled {
                cursor: not-allowed;
                background-color: $grey;
            }
        }
        > p {
            font-size: 14px;
        }
        .separator {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 30px 0;
            span {
                padding: 0 5px;
                position: absolute;
            }
        }
        > a {
            width: 100%;
            height: 42px;
            padding: 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            transition: background-color 0.3s;
            border-radius: 10px;
            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
    p a {
        color: $ortex-color;
        text-decoration: none;
    }
}
