@import "../../pages/variables.scss";

.list-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.btn-user {
    @media screen and (max-width: $tablet-standard-width) {
        margin-bottom: 0 !important;
    }
}
.userName {
    @media screen and (max-width: $tablet-standard-width) {
        font-size: 1rem !important;
    }
}
