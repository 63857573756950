@import "../../pages/variables.scss";

.TableCell {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .styles.company {
        display: flex;
        flex-direction: column;
        font-size: 12px !important;
    }

    .container {
        width: 100%;
    }
    .description {
        min-width: 220px;
    }
    .bold {
        font-weight: bold;
    }
    .row {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .link {
        cursor: pointer;
    }
    .right {
        text-align: right;
    }
    .left {
        text-align: left;
    }
    .direction {
        text-transform: uppercase;
    }
    .ticker div:first-child p {
        width: fit-content;
    }
    .nowrap {
        white-space: nowrap;
    }

    .millify > div {
        display: flex;
        text-align: center;
        p:first-child {
            text-align: right;
        }
        p:last-child {
            text-align: left;
        }
    }
    .subtext div:last-child {
        font-size: 11px;
        p:before {
            content: "(";
        }
        p:after {
            content: ")";
        }
        @media screen and (min-width: $desktop-medium-width) {
            font-size: 12px;
        }
        @media screen and (min-width: $desktop-medium-width) and (min-height: $tablet-medium-width) {
            font-size: 14px;
        }
    }
    .consensus {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: 0 0 2px $black;
        text-align: center;
        font-weight: bold;
        color: $white;
        padding: 5px 0;

        > div {
            height: 26px;
            width: 26px;

            p {
                display: flex;
            }

            p:first-child {
                margin-top: -26px;
                margin-left: 2px;
                font-size: 11px;
            }
            p:last-child {
                margin-top: -4px;
                font-size: 10px;
                margin-left: 13px;

                @media screen and (min-width: $desktop-medium-width) {
                    font-size: 11px;
                }
            }
        }
    }
    .arrow {
        margin-right: 5px;

        svg {
            width: 10px;
            height: 13px;
        }
    }
    .tooltip > div p {
        width: fit-content;
    }
}

.Button {
    width: 20px;
    height: 20px;
    margin: auto;
    cursor: pointer;
    position: relative;
    background-color: transparent;

    svg {
        width: 25px;
        height: 25px;
    }
}

.centeredCells {
    p {
        width: fit-content;
        flex: initial !important;
    }
}

.millify_arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    svg {
        padding-left: 2.5px;
        width: 20px;
        height: 20px;
    }
}

.stockContainer {
    display: flex;
    flex-direction: column;
}

.logo {
    border-radius: 4px;
    height: min-content;
    width: max-content;
    margin-bottom: 5px;
    padding: 0 5px;

    > span {
        height: 25px !important;
        position: static !important;
        img {
            width: unset !important;
            height: 100% !important;
            margin: unset !important;
            position: static !important;
            max-width: 80px !important;
            min-width: unset !important;
            min-height: unset !important;
            max-height: unset !important;
        }

        @media screen and (min-width: $desktop-medium-width) {
            height: 35px !important;
        }
    }
}
