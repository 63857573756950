@import "../../pages/variables.scss";

.PaymentWelcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    height: 75%;

    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
        min-height: 50%;
        margin-bottom: 10px;
        padding: 15px;
        width:80%;
        @media screen and (min-width:$laptop-standard-width) {
            width:100%;
            min-height: 300px;
        }
    }

    .btn {
        width: 100%;
        max-width: 400px;
        font-size: 16px;
        color: $white;

        @media screen and (min-width: $tablet-small-width) {
            display: none !important;
        }
    }
}
