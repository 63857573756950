@import "../../pages/variables.scss";

.header_custom_views {
    display: flex;
    align-items: center;
    justify-content: initial;
    text-align: left;
}

.header_custom_views_title {
    padding-left: 10px;
}

.header_custom_views_state {
    padding-left: 10px;
    opacity: 0.6; /* Real browsers */
    filter: alpha(opacity = 60); /* MSIE */
}
