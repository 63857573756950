@import "../../pages/variables.scss";

.HeaderAutocomplete {
    width: 100%;

    .instrumentsContainer {
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .instrument {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 100%;
        padding: 10px;
        position: relative;
        display: flex;
        border-bottom: none;
        user-select: none;
        cursor: pointer;
        margin-top: 2px;
        margin-bottom: 2px;
        .instrumentContent {
            width: 100%;
            margin-left: 10px;
        }
        .logo {
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            @media screen and (min-width: $mobile-large-width) {
                margin-right: 20px;
            }

            > span {
                height: 22px !important;
                position: static !important;
                @media screen and (min-width: $desktop-medium-width) {
                    height: 28px !important;
                }
                img {
                    width: unset !important;
                    height: 100% !important;
                    margin: unset !important;
                    position: static !important;
                    max-width: 70px !important;
                    min-width: unset !important;
                    min-height: unset !important;
                    max-height: unset !important;
                }
            }
        }
        .instrumentWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            .instrumentInfo {
                width: 100%;
                display: flex;
                align-items: center;
            }
            .ticker {
                font-weight: 700;
                margin-right: 10px;
                flex-shrink: 0;
                width: 80px;
                word-wrap: break-word;
                @media screen and (min-width: $mobile-large-width) {
                    width: fit-content;
                }
            }
            .name {
                margin-right: 5px;
            }
            .type {
                margin-left: auto;
                display: flex;
                align-items: center;
                > .securityType {
                    text-transform: uppercase;
                }
            }
        }
        .instrumentLinks {
            position: relative;
            display: none;
            z-index: 2;

            > a {
                font-size: 10px;
                text-decoration: none;
                margin-right: 8px;

                @media (hover: hover) {
                    &:hover {
                        color: #31aba6 !important;
                    }
                }
            }

            @media screen and (min-width: $tablet-large-width) {
                width: 100%;
                display: flex;
                align-items: center;
            }
        }
        .instrumentMobileLinks {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;
            transform: translateX(100%);
            transition: transform 0.3s ease;
            display: flex;
            align-items: center;

            .instrumentMobileLinksWrapper {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                > a {
                    width: 33%;
                    font-size: 11px;
                    text-decoration: none;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            @media screen and (min-width: $tablet-large-width) {
                display: none;
            }
        }
        > a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 0;

            @media screen and (max-width: $tablet-large-width) {
                display: none;
            }
        }
    }

    .instrument.isOpen .instrumentMobileLinks {
        transform: translateX(0);
    }
}
