@import "../../pages/variables.scss";

.MacroEventsTable {
    height: 100%;
    border-radius: 10px;

    &.with-calendar {
        overflow-y: unset;

        .table {
            margin-bottom: 15px;
            flex-direction: column;

            > div > div {
                border-right-width: 0px;
            }

            .left {
                width: 100%;

            }
        }

        @media screen and (min-width: $tablet-large-width) {
            height: 100%;
            .table {
                height: 100%;
                max-height: unset;
                flex-direction: row;
                border-radius: 10px;
                margin-bottom: 0;

                .left {
                    width: 50%;

                }

                .events-column {
                    display: flex;
                    flex-direction: column;

                    > div {
                        overflow: auto;
                        border-right-width: 2px;
                    }
                }
            }

        }
    }

    .table {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        @media screen and (min-width: $tablet-medium-width) {
            flex-direction: row;
        }
        .events-column {
            flex: 1;
            > p {
                padding: 10px;
                color: $ortex-color;
                font-weight: bold;
                white-space: nowrap;
                position: sticky;
                z-index: 1;
                top: 0;
            }
            > div {
                height: 100%;
                border-right-width: 0;
                border-right-style: solid;
                > div {
                    padding: 10px;
                    .title {
                        display: flex;
                        justify-content: space-between;
                        font-weight: bold;
                        p:last-child {
                            text-align: center;
                            height: fit-content;
                            padding: 3px 10px;
                        }
                    }
                    > p {
                        margin: 5px 0;
                    }
                    .description {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: flex-end;
                        > p {
                            flex: 1;
                            margin-right: 5px;
                            line-height: 20px;
                            white-space: nowrap;
                        }
                        > div {
                            display: flex;
                            align-items: center;
                            > div {
                                border-radius: 50%;
                            }
                        }
                    }
                }
                @media screen and (min-width: $tablet-small-width) {
                    border-right-width: 2px;
                }
            }
            &:last-of-type > div {
                border-right: none;
            }
        }
        &.one-column {
            flex-direction: column;
            > div > div {
                border-right-width: 0px;
            }
        }
    }
}
