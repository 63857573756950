@import "../../pages/variables.scss";

.RootLayout {
    width: 100vw;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    padding-top: env(safe-area-inset-top);

    > main {
        padding-top: calc($header-height-mobile + $ticker-carousel + 10px);
        height: calc(100vh - env(safe-area-inset-top));

        @media screen and (min-width: $tablet-standard-width) {
            padding-top: calc($header-height + $ticker-carousel + env(safe-area-inset-top));
            height: calc(100vh);
        }

        @media screen and (min-width: $laptop-standard-width) {
            padding-top: calc($header-height + env(safe-area-inset-top) + $ticker-carousel);
            height: 100%;
        }

        @media screen and (min-width: $desktop-standard-width) {
            padding-top: calc($header-height + env(safe-area-inset-top) + $ticker-carousel);
        }

        @media screen and (min-width: $desktop-4k-width) {
            padding-top: calc($header-height + env(safe-area-inset-top) + $ticker-carousel-4k);
        }

        #subtitle {
            font-size: 8px;

            @media screen and (min-width: $desktop-4k-width) {
                font-size: 14px;
            }
        }

        .stock-layout {
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100%;
            .content {
                flex: 1;
                height: 0;
                width: 100%;
            }
        }
    }

    .mainStockPage {
        padding-top: calc($header-height-mobile + $stock-navigation-tabs + $ticker-carousel);
        height: calc(100vh - env(safe-area-inset-top));

        @media screen and (min-width: $tablet-standard-width) {
            padding-top: calc($header-height + $stock-navigation-tabs + env(safe-area-inset-top) + $ticker-carousel);
        }

        @media screen and (min-width: $laptop-standard-width) {
            padding-top: calc($header-height + $stock-navigation-tabs + env(safe-area-inset-top) + $ticker-carousel);
        }

        @media screen and (min-width: $desktop-standard-width) {
            padding-top: calc($header-height + $stock-navigation-tabs + env(safe-area-inset-top) + $ticker-carousel);
        }

        @media screen and (min-width: $desktop-4k-width) {
            padding-top: calc($header-height + $stock-navigation-tabs + env(safe-area-inset-top) + $ticker-carousel-4k);
        }
    }
}

.muiContainer {
    height: 100%;
    padding: 1px !important;

    @media screen and (min-width: $tablet-standard-width) {
        padding: 5px !important;
    }

    @media screen and (min-width: $laptop-standard-width) {
        padding: 8px !important;
    }

    @media screen and (min-width: $desktop-standard-width) {
        padding: 12px !important;
    }

    @media screen and (min-width: $desktop-4k-width) {
        padding: 24px !important;
    }
}

.user {
    height: calc(100vh - $header-height);
    transition: padding-left 0.3s;
}

.corporate-actions {
    height: 100%;
}

.commodity-exposure {
    height: 100%;
}

.currency-exposure {
    height: 100%;
}

.trading-signals {
    height: 100%;
}

.short-interest {
    height: 100%;
}

.stock-scores {
    height: 100%;
}

.fundamentals {
    height: 100%;
}

.analysts {
    height: 100%;
}

.insiders {
    height: 100%;
}

.dividend {
    height: 100%;
}

.holders {
    height: 100%;
}

.options {
    height: 100%;
}

.events {
    height: 100%;
}

.peers {
    height: 100%;
}

.eps {
    height: 100%;
}

.overview {
    height: 100%;
}

.stock-stamps {
    height: 100%;
}

.drawerContent {
    padding: 15px;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.tickertapeSettingsBtn {
    position: fixed;
    top: calc($header-height-mobile + env(safe-area-inset-top));
    box-sizing: content-box;
    display: inline-flex;
    z-index: 99;
    height: 25px;

    > button {
        margin: 0 5px;
    }

    @media screen and (min-width: $tablet-standard-width) {
        top: 0;
    }
}
