@import "../../pages/variables.scss";

.SideMenuTrigger {
    
    display: none;

    @media (min-width: $laptop-standard-width) {
        display: block;
        position: fixed;
        top: 75px;
        left: 0;
        width: 20px;
        height: 90vh;
        overflow: visible;
        display: flex;
        align-items: center;
        padding-left: 10px;
        z-index: 1200;
    }      
}

@keyframes slideIn {
    0% {
        transform: translateX(calc(-100% - 10px))
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% - 10px));
    }
}

.SideMenu {
    @media (min-width: $tablet-large-width){
    animation: slideIn 0.2s forwards;
    position: fixed;
    max-height: 80vh;
    overflow-y: auto;
    scale: 1.2;
    /* Styles specific to $tablet-large-width */
    height: fit-content;
    }

    @media (min-width: $laptop-standard-width)  {
        max-height: 80vh;
        overflow-y: auto;
        scale: 1.2;
        height: fit-content;
    }

    @media (min-width: $desktop-standard-width) {
        max-height: 80vh;
        overflow-y: auto;
        scale: 1.2;

        height: fit-content
    }
    @media (min-width: $desktop-4k-width) {
        max-height: 100vh;
        overflow-y: auto;
        scale: 1.8;

        height: fit-content;
    }
}



.SideMenu.HideMenu {
    animation: slideOut 0.2s forwards;
}
