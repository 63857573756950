.settingsContainer {
    min-width: 300px;
}

.settings-item {
    > div {
        width: 100%;
    }

    label {
        width: 100%;
        padding: 0;
        justify-content: space-between;
        margin: 0;
    }
}

.actionBtns {
    display: flex;
}
