@import "../../pages/variables.scss";

.BrandLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: calc(100% - $header-height-mobile - $ticker-carousel);
    width: 100%;
    z-index: 4;
    @media screen and (min-width: $tablet-standard-width) {
        height: calc(100% - $header-height - $ticker-carousel);
    }
    @media screen and (min-width: $desktop-4k-width) {
        height: calc(100% - $header-height - $ticker-carousel-4k);
    }

    .loader {
        margin: 40px;
        position: relative;
        width: 80px;
        height: 80px;
        > div {
            transform-origin: 40px 40px;
            animation: loading 1.2s linear infinite;
        }
        > div::after {
            content: " ";
            display: block;
            position: absolute;
            top: 3px;
            left: 37px;
            width: 6px;
            height: 18px;
            border-radius: 20%;
            background: $ortex-color;
        }
        div:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: -1.1s;
        }
        div:nth-child(2) {
            transform: rotate(30deg);
            animation-delay: -1s;
        }
        div:nth-child(3) {
            transform: rotate(60deg);
            animation-delay: -0.9s;
        }
        div:nth-child(4) {
            transform: rotate(90deg);
            animation-delay: -0.8s;
        }
        div:nth-child(5) {
            transform: rotate(120deg);
            animation-delay: -0.7s;
        }
        div:nth-child(6) {
            transform: rotate(150deg);
            animation-delay: -0.6s;
        }
        div:nth-child(7) {
            transform: rotate(180deg);
            animation-delay: -0.5s;
        }
        div:nth-child(8) {
            transform: rotate(210deg);
            animation-delay: -0.4s;
        }
        div:nth-child(9) {
            transform: rotate(240deg);
            animation-delay: -0.3s;
        }
        div:nth-child(10) {
            transform: rotate(270deg);
            animation-delay: -0.2s;
        }
        div:nth-child(11) {
            transform: rotate(300deg);
            animation-delay: -0.1s;
        }
        div:nth-child(12) {
            transform: rotate(330deg);
            animation-delay: 0s;
        }
    }

    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 30%;
        position: absolute;
        top: 35%;
        left: 35%;

        > i {
            font-size: 25px;
            color: $ortex-color;
        }
    }
}

.stock-page {
    height: calc(100% - $stock-header-mobile - $header-height-mobile - $ticker-carousel);
    @media screen and (min-width: $tablet-standard-width) {
        height: calc(100% - $stock-header-mobile - $header-height - $ticker-carousel);
    }
    @media screen and (min-width: $desktop-standard-width) {
        height: calc(100% - $stock-header - $header-height - $ticker-carousel);
    }
    @media screen and (min-width: $desktop-4k-width) {
        height: calc(100% - $stock-header - $header-height - $ticker-carousel-4k);
    }
}

@keyframes loading {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
