@import "../../pages/variables.scss";

.PaymentPlansPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .plan-selector {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        @media screen and (min-width: $tablet-small-width) {
            width: 100%;
            max-height: 600px;
            flex-direction: row-reverse;
        }
        .card {
            margin: 15px 0; 
            max-width: 380px;
            width: 100%;
            box-sizing: border-box;

            @media screen and (min-width: $tablet-small-width) {
                margin: 15px; 
            }

            div {
                height: 100%;
                width: 100%;
                // flex: 1;
                padding: 5px 10px;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                box-sizing: border-box;

                > span {
                    text-align: left;
                    display: inline-flex;
                    margin: 5px 0;
                }
                .name {
                    align-self: flex-start;
                    span {
                        margin-left: 5px;
                        align-self: center;
                    }
                }
                .features {
                    // height:
                    width: 100%;
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    > span {
                        display: inline-flex;
                        align-items: center;
                        margin: 7px 0;
                    }
                }
                .line {
                    width: 100%;
                    border-width: 0;
                    border-style: solid;
                    border-bottom-width: thin;
                }
            }
        }
    }
    .free-account span {
        color: $ortex-color;
        cursor: pointer;
    }
    .btn {
        width: 80%;
        color: $white;
        font-size: 16px;
        cursor: pointer;
        margin-top: 15px;
        margin-bottom: 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 10px 20px;

        &:disabled{
            color: $grey-lighter;
            background-color: rgb(74, 196, 191, 0.4) !important;
            pointer-events: visible !important;

            &:hover{
                cursor: not-allowed !important; 
            }
        }

        @media screen and (min-width:$laptop-standard-width) {
            display: none;
        }
    }
}