.SizeBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    .progress-bar {
        height: 16px;
        width: 50%;

        &:first-child {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }

        > div {
            height: 100%;
        }
    }
}
