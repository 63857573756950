@import "../../pages/variables.scss";

.Header {
    width: 100%;
    z-index: 7;
    position: fixed;
    display: flex;
    height: calc($header-height-mobile + env(safe-area-inset-top));
    top: 0px;


    @media screen and (min-width: laptop-standard-width) {
        box-shadow: 0px 3px 4px rgb(0 0 0 / 15%);
    }

    @media screen and (min-width: $tablet-standard-width) {
        height: $header-height;
        top: 25px;
        box-shadow: 0px 3px 4px rgb(0 0 0 / 15%);

    }

    @media screen and (min-width: $desktop-standard-width) {
        box-shadow: 0px 3px 4px rgb(0 0 0 / 15%);
    }


    @media screen and (min-width: $desktop-4k-width) {
        height: $header-height;
        top: $ticker-carousel-4k;
        box-shadow: 0px 3px 4px rgb(0 0 0 / 15%);

    }

    > div {
        width: calc(100% - 10px);
        margin: auto 5px 0;
        display: flex;
        align-items: center;

        @media screen and (min-width: $tablet-standard-width) {
            margin: 0;
        }

        > div {
            display: flex;
            align-items: center;
            padding-left: 10px;
        }
        .kebab {
            display: flex;
            @media screen and (min-width: $mobile-medium-width) {
                display: none;
            }
            > button {
                width: 25px;
                height: 25px;
                padding: 0;
                cursor: pointer;
                border: none;
                background-color: transparent;
                svg {
                    width: inherit;
                    height: inherit;
                }
            }
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            height:100%;
            .smallIcon {
                display: inline-block;
                @media screen and (min-width: $tablet-standard-width) {
                    display: none;
                }
            }
            .largeIcon {
                display: none;
                @media screen and (min-width: $tablet-standard-width) {
                    display: inline-block;
                }
            }
        }

        .middleSection {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            @media screen and (min-width: $tablet-medium-width) {
                margin: 0 auto;
            }
            > div {
                &:first-child {
                    display: flex;
                    align-items: center;
                    transition: all 0.2s ease-in 0.3s;
                    @media screen and (min-width: $tablet-medium-width) {
                        width: 320px;
                    }
                }
            }

            .backdrop {
                width: 100vw;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 100;
                opacity: 0;
                height: 0;
                transition: opacity 0.2s linear 0.1s, height 0.1s ease-in-out 0.4s;
            }

            &.searchStarted {
                > div {
                    &:first-child {
                        display: flex;
                        align-items: center;
                        transition: all 0.2s ease-in 0.1s;
                        @media screen and (min-width: $tablet-medium-width) {
                            width: 620px;
                        }
                    }
                }

                .backdrop {
                    opacity: 1;
                    height: 100vh;
                    transition: opacity 0.2s linear 0.3s, height 0.1s ease-in-out 0.1s;
                }
            }
        }
        
        
       

        .search {
            height: fit-content;
            width: 100%;
            transition: all 0.3s ease-in-out;
            &:focus-within {
                border-bottom: none !important;
            }

            

            input {
                display: inline-block;
                background-color: transparent;

                &:focus::placeholder {
                    color: transparent;
                }
            }

            input:focus + span {
                height: 2px;
                background-position: -4rem top;
                background-repeat: no-repeat;
                animation-name: shimmer;
                animation-duration: 2s;
                animation-iteration-count: infinite;
                background-size: 4rem 100%;
            }
            @keyframes shimmer {
                0% {
                    background-position: -4rem top;
                }

                70% {
                    background-position: 10.1rem top;
                }

                100% {
                    background-position: 10.1rem top;
                }
            }
            button {
                width: 30px;
                display: flex;
                align-items: center;
                padding: 0 10px 0 7px;
                background-color: transparent;
                cursor: pointer;
                svg {
                    width: 13px;
                    height: 13px;
                }
            }
            .loader {
                position: absolute;
                right: 30px;
                bottom: -4px;
            }
        }

        .theme {
            flex: 1;
            @media screen and (min-width: $mobile-medium-width) {
                flex: 0;
            }
            @media screen and (min-width: $mobile-large-width) {
                flex: 1;
            }
            @media screen and (min-width: $tablet-medium-width) {
                flex: 0;
            }
            label {
                width: 22px;
                height: 22px;
                position: relative;
                input {
                    display: none;
                }
                div {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition: 0.5s;
                    border-radius: 50%;
                }
                div:before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    left: 1px;
                    top: 1px;
                    -webkit-transition: 0.2s;
                    transition: 0.2s;
                    border-radius: 50%;
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
                input:checked + div:before {
                    -webkit-transform: rotate(45deg) scaleX(-1);
                    transform: rotate(45deg) scaleX(-1);
                }
            }
        }
        .instruments-carousel {
            flex: 1;
            width: 0;
            margin: 0;
            display: none;
            @media screen and (min-width: $tablet-small-width) {
                display: flex;
            }
        }
        .upgrade {
            display: none;
            @media screen and (min-width: $mobile-medium-width) {
                display: flex;
            }
            button {
                font-size: 13px;
                padding: 4px 7px;
                border-radius: 10px;
                border: 2px solid $ortex-color;
                background-color: transparent;
                transition: background-color 0.3s;
                white-space: nowrap;
                cursor: pointer;
                @media (hover: hover) {
                    &:hover {
                        background-color: $ortex-color;
                    }
                }
            }
        }

        .badges {
            padding-left: 15px;
        }
    }

    
}

.mobileSearchDrawer {
    padding: 10px 25px;
    .mobileSearchDrawerContent {
        position: relative;
        height: fit-content;
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;

        &:focus-within {
            border-bottom: none !important;
        }

        input {
            width: 100%;
            display: inline-block;
            background-color: transparent;
            border: none;
            outline: none;

            &:focus::placeholder {
                color: transparent;
            }
        }

        input:focus + span {
            height: 1px;
            background-position: -4rem top;
            background-repeat: no-repeat;
            animation-name: shimmer;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            background-size: 4rem 100%;
        }
        @keyframes shimmer {
            0% {
                background-position: -4rem top;
            }

            70% {
                background-position: 10.1rem top;
            }

            100% {
                background-position: 10.1rem top;
            }
        }
        button {
            display: flex;
            align-items: center;
            padding: 4px 10px 4px 7px;
            background-color: transparent;
            border: none;
            cursor: pointer;

            svg {
                width: 16px;
                height: 16px;
            }
        }
        .loader {
            position: absolute;
            right: 20px;
            display: flex;
            align-items: center;
            z-index: 999;
        }
    }

    .universe {
        display: none;

        @media screen and (min-width: $mobile-medium-width) {
            display: flex;
        }
        @media screen and (min-width: $mobile-large-width) {
            flex: 0;
        }
    }
}

@keyframes exchangesCarrousel {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.searchInput {
    height: 35px;
    position: relative;
    z-index: 200 !important;

}

.textLoopText {
    color: $ortex-color;
}

.placeholder {
    position: absolute;
    z-index: 200;
    top: 10px;
    left: 15%;
    pointer-events: none;
    
}

.placeholderText {
    display: flex;
}